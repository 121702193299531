import { ThemeOptions } from '@mui/material';

export const blueGrey = {
    50: '#F7F8FA',
    100: '#F0F2F5',
    200: '#E4E8EE',
    300: '#D3D7E0',
    400: '#AEB3BD',
    500: '#8B919E',
    600: '#656A75',
    700: '#515761',
    800: '#363A42',
    900: '#1A1D21',
};

const onboardingTheme: ThemeOptions = {
    colorSchemes: {
        light: {
            palette: {
                text: {
                    primary: '#1A1D21',
                    secondary: 'rgba(26, 29, 33, 0.65)',
                    disabled: 'rgba(26, 29, 33, 0.4)',
                    _states: {
                        hover: 'rgba(26, 29, 33, 0.04)',
                        selected: 'rgba(26, 29, 33, 0.08)',
                        focus: 'rgba(26, 29, 33, 0.12)',
                        focusVisible: 'rgba(26, 29, 33, 0.3)',
                    },
                },
                primary: {
                    main: '#006DFF',
                    dark: '#115AED',
                    light: '#0090FF',
                    contrastText: '#FFFFFF',
                    _states: {
                        hover: 'rgba(17, 92, 237, 0.04)',
                        selected: 'rgba(17, 92, 237, 0.08)',
                        focus: 'rgba(17, 92, 237, 0.12)',
                        focusVisible: 'rgba(17, 92, 237, 0.3)',
                        outlinedBorder: 'rgba(17, 92, 237, 0.5)',
                    },
                },
                secondary: {
                    main: '#515761',
                    dark: '#363A42',
                    light: '#8B919E',
                    contrastText: '#FFFFFF',
                    _states: {
                        hover: 'rgba(26, 29, 33, 0.04)',
                        selected: 'rgba(26, 29, 33, 0.08)',
                        focus: 'rgba(26, 29, 33, 0.12)',
                        focusVisible: 'rgba(26, 29, 33, 0.3)',
                        outlinedBorder: 'rgba(26, 29, 33, 0.5)',
                    },
                },
                action: {
                    active: 'rgba(26, 29, 33, 0.56)',
                    hover: 'rgba(26, 29, 33, 0.04)',
                    selected: 'rgba(26, 29, 33, 0.08)',
                    focus: 'rgba(26, 29, 33, 0.12)',
                    disabled: 'rgba(26, 29, 33, 0.38)',
                    disabledBackground: 'rgba(26, 29, 33, 0.12)',
                },
                error: {
                    main: '#D80038',
                    dark: '#CB0031',
                    light: '#F34059',
                    contrastText: '#FFFFFF',
                    _states: {
                        hover: 'rgba(216, 0, 56, 0.04)',
                        selected: 'rgba(216, 0, 56, 0.08)',
                        focusVisible: 'rgba(216, 0, 56, 0.3)',
                        outlinedBorder: 'rgba(216, 0, 56, 0.5)',
                    },
                },
                warning: {
                    main: '#E96800',
                    light: '#F99300',
                    contrastText: '#FFFFFF',
                    _states: {
                        focusVisible: 'rgba(239, 108, 0, 0.3)',
                        hover: 'rgba(239, 108, 0, 0.04)',
                    },
                },
                info: {
                    main: '#0091DE',
                    dark: '#0060AA',
                    light: '#00B3FF',
                    contrastText: '#FFFFFF',
                    _states: {
                        hover: 'rgba(0, 145, 222, 0.04)',
                        selected: 'rgba(0, 145, 222, 0.08)',
                        focusVisible: 'rgba(0, 145, 222, 0.3)',
                        outlinedBorder: 'rgba(0, 145, 222, 0.5)',
                    },
                },
                success: {
                    main: '#008C22',
                    dark: '#006C0B',
                    light: '#00C044',
                    contrastText: '#FFFFFF',
                    _states: {
                        hover: 'rgba(0, 140, 34, 0.04)',
                        selected: 'rgba(0, 140, 34, 0.08)',
                        focusVisible: 'rgba(0, 140, 34, 0.3)',
                        outlinedBorder: 'rgba(0, 140, 34, 0.5)',
                    },
                },
                divider: 'rgba(26, 29, 33, 0.12)',
                _components: {
                    avatar: {
                        fill: '#AEB3BD',
                    },
                    input: {
                        standard: {
                            enabledBorder: 'rgba(26, 29, 33, 0.42)',
                            hoverBorder: '#1A1D21',
                        },
                        filled: {
                            enabledFill: 'rgba(26, 29, 33, 0.06)',
                            hoverFill: 'rgba(26, 29, 33, 0.09)',
                        },
                        outlined: {
                            enabledBorder: 'rgba(26, 29, 33, 0.23)',
                            hoverBorder: '#1A1D21',
                        },
                    },
                    switch: {
                        knobFillEnabled: '#F7F8FA',
                        slideFill: '#1A1D21',
                        knowFillDisabled: '#F0F2F5',
                    },
                    rating: {
                        enabledBorder: 'rgba(26, 29, 33, 0.23)',
                    },
                    snackbar: {
                        fill: '#00072D',
                    },
                    chip: {
                        defaultCloseFill: '#1A1D21',
                        defaultHoverFill: 'rgba(26, 29, 33, 0.12)',
                        defaultEnabledBorder: '#AEB3BD',
                        defaultFocusFill: 'rgba(26, 29, 33, 0.2)',
                    },
                    tooltip: {
                        fill: '#00072D',
                    },
                    backdrop: {
                        fill: 'rgba(26, 29, 33, 0.5)',
                    },
                    appBar: {
                        defaultFill: '#F0F2F5',
                    },
                    breadcrumbs: {
                        collapseFill: '#F0F2F5',
                    },
                    alert: {
                        error: {
                            color: '#560016',
                            background: '#FDE7EC',
                        },
                        warning: {
                            color: '#5D2A00',
                            background: '#FFF6E5',
                        },
                        info: {
                            color: '#003A59',
                            background: '#E6FAFF',
                        },
                        success: {
                            color: '#00380E',
                            background: '#ECFBF2',
                        },
                    },
                    stepper: {
                        connector: '#AEB3BD',
                    },
                },
                common: {
                    white_states: {
                        main: '#FFFFFF',
                        focusVisible: 'rgba(255, 255, 255, 0.3)',
                    },
                },
            },
        },
        dark: {
            palette: {
                primary: {
                    main: '#86C9FF',
                    dark: '#00A2FF',
                    light: '#E2F2FF',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                secondary: {
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                    main: '#CE93D8',
                },
                error: {
                    main: '#FA2041',
                    dark: '#D80038',
                    light: '#E76979',
                    contrastText: '#FFFFFF',
                },
                warning: {
                    main: '#FAA325',
                    dark: '#EF7800',
                    light: '#FBB34C',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                    _states: {
                        focusVisible: 'rgba(255, 167, 38, 0.3)',
                        hover: 'rgba(255, 167, 38, 0.08)',
                    },
                },
                info: {
                    main: '#00BFFF',
                    dark: '#0091DE',
                    light: '#03CBFD',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                success: {
                    main: '#00CC5E',
                    dark: '#009D2E',
                    light: '#3DD67D',
                    contrastText: 'rgba(0, 0, 0, 0.87)',
                },
                elevation: {
                    outlined: 'rgba(26, 29, 33, 0.12)',
                },
                _components: {
                    avatar: {
                        fill: '#656A75',
                    },
                    stepper: {
                        connector: '#656A75',
                    },
                },
                common: {
                    white_states: {
                        main: '#FFFFFF',
                        focusVisible: 'rgba(255, 255, 255, 0.3)',
                    },
                },
            },
        },
    },
    typography: {
        button: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        h1: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        h2: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        h3: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
            fontWeight: 600,
        },
        h4: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
            fontWeight: 600,
        },
        h5: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
            fontWeight: 500,
        },
        h6: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        body1: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        body2: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        subtitle1: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        subtitle2: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        overline: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
        caption: {
            fontFamily: 'Plus Jakarta Sans, Helvetica, Arial, sans-serif',
        },
    },
    breakpoints: {
        keys: ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'],
        values: {
            xs: 0,
            sm: 600,
            md: 1024,
            lg: 1200,
            xl: 1536,
            xxl: 1856,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: {
                        size: 'large',
                    },
                    style: {
                        textTransform: 'none',
                        fontWeight: 600,
                        lineHeight: '173.333%',
                        letterSpacing: '0.46px',
                    },
                },
                {
                    props: {
                        size: 'medium',
                    },
                    style: {
                        textTransform: 'none',
                        fontWeight: 600,
                        lineHeight: '24px' /* 171.429% */,
                        letterSpacing: '0.4px',
                    },
                },
            ],
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#006DFF',
                },
            },
        },
    },
};

export default onboardingTheme;
